body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #fbfbfb;
}

.card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  min-height: 400px;
  background: #fff;
  box-shadow: 0 20px 50px rgba(0, 0, 0, .1);
  border-radius: 10px;
  transition: 0.5s;
}

.card:hover {
  box-shadow: 0 30px 70px rgba(0, 0, 0, .2);
}

.card .box {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
}

.card .box .img {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
}

.card .box .img img {
  width: 100%;
  height: 100%;
}

.card .box .name h2 {
  font-size: 25px;
  color: #262626;
  margin-bottom: 9px;
}

.card .box .name h3 {
  font-size: 16px;
  color: #a8a8a8;
}

.card .box .name {
  margin: 25px 10px 20px 10px;
}

.card .box h2 span {
  font-size: 14px;
  background: #305fb7;
  color: #fff;
  display: inline-block;
  padding: 4px 10px;
  border-radius: 15px;
}

.card .box p {
  color: #262626;
}

.card .box span {
  display: inline-flex;
}

.card .box ul {
  margin: 0;
  padding: 0;
}

.card .box ul li {
  list-style: none;
  float: left;
}

.card .box ul li a {
  display: block;
  color: #aaa;
  width: 20px;
  margin: 0 10px;
  font-size: 20px;
  transition: 0.5s;
  text-align: center;
}

.card .box ul li:hover a {
  color: #305fb7;
  transform: rotateY(360deg);
}

.bybyte-banner {
  position: fixed;
  top: 0;
  right: 0;
  width: 150px;
  height: 150px;
  overflow: hidden;
  z-index: 1000;
  border-radius: 0 10px 0 0;
}

.bybyte-banner::before {
  content: '';
  position: absolute;
  top: -50%;
  right: -50%;
  width: 100%;
  height: 100%;
  background-color: #1a1a1a;
  transform: rotate(45deg);
  z-index: -1;
  border-radius: 0 10px 0 0;
}

.bybyte-banner a {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  transition: transform 0.3s ease;
}

.bybyte-banner a:hover {
  transform: scale(1.1);
}

.bybyte-banner img {
  width: 100%;
  height: 100%;
}
